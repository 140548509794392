







import { Component, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TopSchedule from '@/components/organisms/v3/dashboard/TopSchedule.vue'

@Component({
  components: {
    SidebarSwitcher,
    TopSchedule,
  },
})
export default class StudentSchedule extends Vue {
  private async loginUser() {
    await Vue.prototype.$http.httpWithToken.get('/v3/users/loginUser').then((res: any) => {
      const isOnAir = res.data.isStudentOfOnairAcademy
      if (isOnAir) {
        return
      }
      if (!res.data.showCalendarAndGradeBook) {
        this.$router.push({ path: '/student/v3/dashboard' })
      }
    })
  }
  private async mounted() {
    await this.loginUser()
  }
}
